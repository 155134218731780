import axios, { AxiosInstance, AxiosResponse } from "axios";

interface ITrackNameItem {
  trackName: string;
  trackNameDescription: string;
}

interface ITrackNameItemResponse {
  hasError: boolean;
  errorMessage: string;
  result: ITrackNameItem[] | null;
}

interface IResultItem {
  trackName: string;
  trackNameDescription: string;
  createDate: Date;
  runningDate: Date;
  driverName: string;
  machineName: string;
  tireName: string;
  comment: string;
  lapTime: number;
}

interface IResultItemResponse {
  hasError: boolean;
  errorMessage: string;
  result: IResultItem[] | null;
}

class WebAPIService {
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: "https://ext-app2.tarohanako.com/api",
      headers: {
        "Content-type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    });
  }

  getTrackNames(): Promise<ITrackNameItemResponse> {
    const result: ITrackNameItemResponse = {
      hasError: true,
      errorMessage: "unknown error",
      result: null,
    };

    return new Promise<ITrackNameItemResponse>((resolve, reject) => {
      this.client
        .get<ITrackNameItemResponse>(`trackNames`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((ex) => {
          result.errorMessage = ex;
          reject(result);
        });
    });
  }

  getRegistedRecords(): Promise<IResultItemResponse> {
    const result: IResultItemResponse = {
      hasError: true,
      errorMessage: "unknown error",
      result: null,
    };

    return new Promise<IResultItemResponse>((resolve, reject) => {
      this.client
        .get<IResultItemResponse>(`registedRecords`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((ex) => {
          result.errorMessage = ex;
          reject(result);
        });
    });
  }

  getUpdateRanking(): Promise<IResultItemResponse> {
    const result: IResultItemResponse = {
      hasError: true,
      errorMessage: "unknown error",
      result: null,
    };

    return new Promise<IResultItemResponse>((resolve, reject) => {
      this.client
        .get<IResultItemResponse>(`updateRanking`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((ex) => {
          result.errorMessage = ex;
          reject(result);
        });
    });
  }

  getRanking(trackName: string): Promise<IResultItemResponse> {
    const result: IResultItemResponse = {
      hasError: true,
      errorMessage: "unknown error",
      result: null,
    };

    return new Promise<IResultItemResponse>((resolve, reject) => {
      this.client
        .get<IResultItemResponse>(`ranking?trackName=${trackName}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((ex) => {
          result.errorMessage = ex;
          reject(result);
        });
    });
  }
}

export default new WebAPIService();
