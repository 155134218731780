























































































import Vue from "vue";

export default Vue.extend({
  name: "App",
  data: () => ({
    drawer: false,
    windowSize: {
      x: 0,
      y: 0,
    },
    generalMenuItems: [
      { id: 1, caption: "ホーム", icon: "mdi-home", to: "/" },
      { id: 2, caption: "ランキング", icon: "mdi-crown", to: "/ranking" },
      {
        id: 3,
        caption: "走行ログ解析",
        icon: "mdi-car-sports",
        to: "/analyze",
      },
    ],
    userMenuItems: [{ caption: "メッセージ", icon: "mdi-mailbox-open-up" }],
  }),
  mounted() {
    this.onResize();
  },

  computed: {
    style() {
      return "height: " + this.windowSize.y * 0.8 + "px;";
    },
  },

  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
  },
  components: {},
});
