






























import Vue from "vue";
import NewTopics from "../components/NewTopics.vue";
import NewRecords from "../components/NewRecords.vue";

export default Vue.extend({
  name: "Home",

  components: {
    NewTopics,
    NewRecords,
  },
});
