





























import Vue from "vue";
import WebAPIService from "../models/WebAPIService";

class TopicsItem {
  id: number;
  trackName: string;
  lapTime: string;
  driverName: string;

  zeroPadding(num: number, length: number): string {
    return ("0000000000" + num).slice(-length);
  }

  constructor(
    id: number,
    trackName: string,
    lapTime: number,
    driverName: string
  ) {
    // id
    this.id = id;

    // トラック名
    this.trackName = trackName;

    // ラップタイムを文字列形式で格納
    const dateTime = new Date(1900, 1, 1, 0, 0, 0, 0);
    dateTime.setSeconds(Math.round(lapTime * 100) / 100);
    const minute = Math.trunc(lapTime / 60);
    const seconds = Math.trunc(lapTime - 60 * minute);
    const milliseconds = Math.trunc((lapTime - Math.trunc(lapTime)) * 1000);
    this.lapTime =
      this.zeroPadding(minute, 2) +
      " : " +
      this.zeroPadding(seconds, 2) +
      " . " +
      this.zeroPadding(milliseconds, 3);

    // ドライバ名
    this.driverName = driverName;
    if (this.driverName == "") {
      this.driverName = "<<ドライバ名未登録>>";
    }
  }
}

export default Vue.extend({
  name: "NewTopics",

  data: () => ({
    topics: [] as Array<TopicsItem>,
  }),
  mounted() {
    WebAPIService.getRegistedRecords()
      .then((res) => {
        if (res.result != null) {
          let id = 0;
          for (const item of res.result) {
            this.topics.push(
              new TopicsItem(
                id,
                item.trackNameDescription,
                item.lapTime,
                item.driverName
              )
            );

            id++;
          }
        }
      })
      .catch((res) => {
        console.error(res);
      });
  },
});
